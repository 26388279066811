import autoBind from 'auto-bind';
import { queryKeyboardFocusableAll } from '@emartech/ui-framework-utils';
import { popupLeaveTimeout } from '../constants';
import flipper from '../../../../utils/flipper/index.js';

class PopupEvents {
  constructor(coreEvents) {
    autoBind(this);

    this.coreEvents = coreEvents;
    this.state = coreEvents.state;
    this.utils = coreEvents.utils;
    this.refs = coreEvents.refs;

    if (flipper.isOn('ui_tooltip_performance')) {
      this.createPopup = this.coreEvents.component.createPopup;
    }

    this._leaveTimer = null;
  }

  open() {
    if (this.state.isDisabled) { return; }

    if (flipper.isOn('ui_tooltip_performance')) {
      if (!this.utils.popup) {
        this.createPopup();
      }
    }

    this.utils.popup.open();
  }

  close() {
    this.utils.popup?.close();
  }

  clearCloseTimeout() {
    if (!this.state.hasAction) { return; }

    clearTimeout(this._leaveTimer);
  }

  closeWithTimeout() {
    if (this.state.hasAction) {
      this._leaveTimer = setTimeout(this.close, popupLeaveTimeout);
    } else {
      this.close();
    }
  }

  onMouseEnter() {
    if (!this.state.hasAction) { return; }

    this.clearCloseTimeout();
  }

  onMouseLeave() {
    if (!this.state.hasAction) { return; }

    this.closeWithTimeout();
  }

  onFocusOutForward() {
    if (this.state.isTooltipContentFocusable) {
      this.coreEvents.tooltip.focusLastElement();

      this.coreEvents.tooltip.toggleFocusInCatcher(true);
      this.coreEvents.tooltip.toggleFocusOutCatcher(false);
    } else {
      this.coreEvents.tooltip.focusFocusOutCatcher();
    }
  }

  onFocusOutBackward() {
    this.coreEvents.tooltip.focusFocusInCatcher();

    this.coreEvents.tooltip.toggleFocusOutCatcher(true);
  }

  focusFirstElement() {
    const focusableElements = queryKeyboardFocusableAll(this.refs.popupContent, { includeHidden: true });

    if (!focusableElements.length) { return; }

    const firstFocusableElement = focusableElements[0];
    firstFocusableElement.focus();
  }

  focusLastElement() {
    const focusableElements = queryKeyboardFocusableAll(this.refs.popupContent, { includeHidden: true });

    if (!focusableElements.length) { return; }

    const lastFocusableElement = focusableElements.pop();
    lastFocusableElement.focus();
  }
}

export default PopupEvents;
