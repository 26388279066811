import { ResizeObserver, debounce, queryKeyboardFocusableAll } from '@emartech/ui-framework-utils';
import { isPopupOpened, addOpenedPopup, removeOpenedPopup, getBoundaryElement, getLastOpenedPopup } from './utils.js';
import flipper from '../flipper/index.js';

export const POPUP_AUTO_CLOSE_DELAY = 3000;

export class Popup {
  #validTypes = ['error', 'success', 'info', 'warning'];

  constructor(opener, content, options = {}, { global, createPopper, floatUtility }) {
    const {
      arrow = true,
      closeOnOutsideClick = true,
      closeOnEscPress = true,
      elementToFocusOnOpen = null,
      elementToFocusOnClose = opener,
      matchOpenerWidth = false,
      offset = 12,
      onBeforeOpen = () => {},
      onAfterOpen = () => {},
      onBeforeClose = () => {},
      onAfterClose = () => {},
      onOutsideClickClose = () => {},
      onEscPressClose = () => {},
      onFocusOutForward = () => {},
      onFocusOutBackward = () => {},
      loopFocus = false,
      noPadding = false,
      placement = 'bottom',
      type = '',
      autoClose = false
    } = options;


    this._global = global;
    this._createPopper = createPopper;
    this._floatUtility = floatUtility;

    this._opener = opener;
    this._content = content;
    this._content.classList.add('e-popup-panel');

    if (this.#validTypes.includes(type)) {
      this._content.classList.add(`e-popup-panel-${type}`);
    }

    if (noPadding) {
      this._content.classList.add('e-popup-panel-no_padding');
    }

    this._focusOutCatcherElementStart = this._createFocusOutCatcher();
    this._focusOutCatcherElementEnd = this._createFocusOutCatcher();

    this._arrowElement = document.createElement('div');
    this._arrowElement.classList.add('e-popup-panel__arrow');
    this._content.remove();

    this._arrowEnabled = arrow;
    this._offset = offset;
    this._matchOpenerWidth = matchOpenerWidth;
    this._isAutoClose = autoClose;

    this._closeOnOutsideClick = closeOnOutsideClick;
    this._closeOnEscPress = closeOnEscPress;

    this._elementToFocusOnOpen = elementToFocusOnOpen;
    this._elementToFocusOnClose = elementToFocusOnClose;

    this._loopFocus = loopFocus;

    this._hooks = {};
    this._hooks.onBeforeOpen = onBeforeOpen;
    this._hooks.onAfterOpen = onAfterOpen;
    this._hooks.onBeforeClose = onBeforeClose;
    this._hooks.onAfterClose = onAfterClose;
    this._hooks.onOutsideClickClose = onOutsideClickClose;
    this._hooks.onEscPressClose = onEscPressClose;
    this._hooks.onFocusOutForward = onFocusOutForward;
    this._hooks.onFocusOutBackward = onFocusOutBackward;

    this._openerResizeObserver = new ResizeObserver(() => {
      this.updateWidth();
      this.updatePosition();
    });

    this._contentResizeObserver = new ResizeObserver(() => {
      this.updatePosition();
    });

    this._popper = this._createPopper(this._opener, this._content, { placement });
    this._debouncedPopperUpdate = debounce(this._popper.update);
  }

  get isOpened() {
    return isPopupOpened(this._global, this._opener);
  }

  get isOpenedLast() {
    const lastOpenedPopup = getLastOpenedPopup(this._global);

    return !!lastOpenedPopup && lastOpenedPopup.opener === this._opener;
  }

  get closeOnOutsideClick() {
    return this._closeOnOutsideClick;
  }

  get closeOnEscPress() {
    return this._closeOnEscPress;
  }

  open({ preventAutoFocus = false } = {}) {
    if (this.isOpened) { return; }

    this._hooks.onBeforeOpen(this._opener, this._content);

    if (this._arrowEnabled) {
      this._content.insertAdjacentElement('afterbegin', this._arrowElement);
    }

    this._content.insertAdjacentElement('afterbegin', this._focusOutCatcherElementStart);

    addOpenedPopup(this._global, { type: 'popup', opener: this._opener, element: this._content, popup: this });
    this._floatUtility.float(this._content, this._opener);

    this.updatePosition();
    this._contentResizeObserver.observe(this._content);

    if (this._matchOpenerWidth) {
      this._openerResizeObserver.observe(this._opener);
      this.updateWidth();
    }

    if (!preventAutoFocus && this._elementToFocusOnOpen) {
      this._elementToFocusOnOpen.focus();
    }

    this._content.insertAdjacentElement('beforeend', this._focusOutCatcherElementEnd);

    this._hooks.onAfterOpen(this._opener, this._content);

    if (this._isAutoClose) {
      setTimeout(() => this.close(), POPUP_AUTO_CLOSE_DELAY);
    }
  }

  close({ preventAutoFocus = false } = {}) {
    this._arrowElement.remove();
    this._focusOutCatcherElementStart.remove();
    this._focusOutCatcherElementEnd.remove();

    if (!this.isOpened) { return; }

    this._hooks.onBeforeClose(this._opener, this._content);

    removeOpenedPopup(this._global, this._opener);
    this._floatUtility.remove(this._content);

    this._contentResizeObserver.unobserve(this._content);

    if (this._matchOpenerWidth) {
      this._openerResizeObserver.unobserve(this._opener);
    }

    if (!preventAutoFocus && this._elementToFocusOnClose) {
      this._elementToFocusOnClose.focus();
    }

    this._hooks.onAfterClose(this._opener, this._content);

    if (flipper.isOn('ui_tooltip_performance')) {
      this._popper.setOptions({
        modifiers: [
          {
            name: 'eventListeners',
            enabled: false
          }
        ]
      });
    }
  }

  destroy(options) {
    this.close(options);
    this._popper.destroy();
  }

  toggle({ preventAutoFocus = false } = {}) {
    if (this.isOpened) {
      this.close({ preventAutoFocus });
    } else {
      this.open({ preventAutoFocus });
    }
  }

  updatePosition() {
    if (flipper.isOn('ui_tooltip_performance')) {
      if (!this.isOpened) { return; }
    }

    const flipModifier = {
      name: 'flip',
      options: {
        boundary: getBoundaryElement(this._global),
        fallbackPlacements: ['top', 'bottom', 'right', 'left', 'bottom']
      }
    };

    this._popper.setOptions({
      modifiers: [
        { name: 'arrow', enabled: this._arrowEnabled, options: { element: this._arrowElement, padding: 8 } },
        { name: 'offset', options: { offset: [0, this._offset] } },
        flipModifier,
        { name: 'preventOverflow', options: { boundary: getBoundaryElement(this._global) } }
      ]
    });

    this._debouncedPopperUpdate();
  }

  updateWidth() {
    const openerWidth = this._global.getComputedStyle(this._opener).getPropertyValue('width');

    this._content.style.minWidth = openerWidth;
  }

  runHook(hookName) {
    if (typeof this._hooks[hookName] !== 'function') { return; }

    this._hooks[hookName](this._opener, this._content);
  }

  _createFocusOutCatcher() {
    const element = document.createElement('span');
    element.classList.add('e-popup-panel__focus_out_catcher');
    element.tabIndex = 0;

    element.addEventListener('focus', () => this._onFocusOutCatcherFocus(element));

    return element;
  }

  _onFocusOutCatcherFocus(element) {
    if (!this._loopFocus) {
      this.close();
    }

    if (element === this._focusOutCatcherElementStart) {
      this._hooks.onFocusOutBackward(this._opener, this._content);
    } else if (element === this._focusOutCatcherElementEnd) {
      this._hooks.onFocusOutForward(this._opener, this._content);
    }

    if (!this._loopFocus) { return; }

    const focusableElements = queryKeyboardFocusableAll(this._content);

    if (focusableElements.length <= 2) { return; }

    if (element === this._focusOutCatcherElementStart) {
      focusableElements[focusableElements.length - 2].focus();
    } else if (element === this._focusOutCatcherElementEnd) {
      focusableElements[1].focus();
    }
  }
}
